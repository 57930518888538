/*
 * Background
 * @description Background styling
 * @author Vismntas Vaidalauskis
 * @copyright 2022, Giraffes 4 Zebras B.V, All rights reserved.
 * @version 2.0
 */

// Settings
// =====================================================================================================================
$backgrounds: (
        "primary":(
                "color": $color-primary,
        )
) !default;

// Styling
// =====================================================================================================================

.u-bg{
  background: no-repeat center center / cover;
  @each $type, $settings in $backgrounds {
    &--#{$type} {
      @extend .u-bg;
      background-color: map-get($settings, "background");
      @if map-get($settings, "color") {
        color: map-get($settings, "color");
      }
    }
  }
}