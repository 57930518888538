/*
 * Form response styling
 * @description Button styling
 * @author Vismntas Vaidalauskis
 * @copyright 2022, Giraffes 4 Zebras B.V, All rights reserved.
 * @version 2.0
 */

@use "sass:math";
@import "icons";

// Settings
// =====================================================================================================================
$f-states: (
        error:(
                color: $error,
                icon: "error"
        ),
        success:(
                color: $success,
                icon: "success"
        ),
        warning:(
                color: $warning,
                icon: "warning"
        )
)!default;

// Styling
// =====================================================================================================================
.flex-form{
  @each $type, $settings in $f-states {
    .is-#{$type} {
      select,
      textarea,
      input:not([type='submit']) {
        $c: map-get($settings, "color");
        border-color: $c;
        @if map-get($settings, "icon") == "success" {
          @include is-success($c);
        }
        @if map-get($settings, "icon") == "error" {
          @include is-error($c);
        }
        @if map-get($settings, "icon") == "warning" {
          @include is-warning($c);
        }
      }
      .radio-wrapper__label {
        color: map-get($settings, "color");
      }
    }
  }
  select[disabled],
  textarea[disabled],
  input[disabled]{
    opacity: 0.5;
    cursor: not-allowed;
  }
}