/*
 * Social
 * @description Button styling
 * @author Vismntas Vaidalauskis
 * @copyright 2022, Giraffes 4 Zebras B.V, All rights reserved.
 * @version 2.0
 */

.c-social {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media screen and (min-width: 771px){
    justify-content: space-around;
  }
  li {
    margin: 0 5px;
  }

  svg {
    width: 30px;
    height: 30px;
    @media screen and (min-width: 351px) {
      width: 40px;
      height: 40px;
    }
  }

  a:not(:hover) svg {
    .bg {
      fill: $color-primary;
    }

    *:not(.bg) {
      fill: $color-black;
    }
  }
}