/*
 * @description image to background image styling
 * @author Vismntas Vaidalauskis
 * @copyright 2022, Giraffes 4 Zebras B.V, All rights reserved.
 */


.u-img-container {
  position: relative;
  img[src=''] {
    display: none;
  }
  &--cover{
    @extend .u-img-container;
    background: url('../img/no-image.svg') no-repeat center center / cover;
    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      width: 100%;
      height: 100%;
      margin: auto;

      pointer-events: none;

      object-fit: cover;
    }
  }
  &--contain{
    display: flex;
    justify-content: center;
    align-items: center;

    @extend .u-img-container;
    img {
      width: auto;
      max-width: 100%;
      height: auto;
      max-height: 100%;
    }
  }
}