@mixin is-error ($c:$color-primary) {
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
  background-size: 1.5em 1.5em;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 51.976 51.976' style='enable-background:new 0 0 51.976 51.976;' xml:space='preserve'%3E%3Cpath fill='#{url-friendly-colour($c)}' d='M44.373,7.603c-10.137-10.137-26.632-10.138-36.77,0c-10.138,10.138-10.137,26.632,0,36.77s26.632,10.138,36.77,0 C54.51,34.235,54.51,17.74,44.373,7.603z M36.241,36.241c-0.781,0.781-2.047,0.781-2.828,0l-7.425-7.425l-7.778,7.778 c-0.781,0.781-2.047,0.781-2.828,0c-0.781-0.781-0.781-2.047,0-2.828l7.778-7.778l-7.425-7.425c-0.781-0.781-0.781-2.048,0-2.828 c0.781-0.781,2.047-0.781,2.828,0l7.425,7.425l7.071-7.071c0.781-0.781,2.047-0.781,2.828,0c0.781,0.781,0.781,2.047,0,2.828 l-7.071,7.071l7.425,7.425C37.022,34.194,37.022,35.46,36.241,36.241z'/%3E%3C/svg%3E%0A");
  padding-right: calc(20px + 1.5em);
}
@mixin is-success ($c:$color-primary) {
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
  background-size: 1.5em 1.5em;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 52 52' enable-background='new 0 0 52 52' xml:space='preserve'%3E%3Cpath fill='#{url-friendly-colour($c)}' d='M26,2C12.7,2,2,12.7,2,26s10.7,24,24,24s24-10.7,24-24S39.3,2,26,2z M39.4,20L24.1,35.5 c-0.6,0.6-1.6,0.6-2.2,0L13.5,27c-0.6-0.6-0.6-1.6,0-2.2l2.2-2.2c0.6-0.6,1.6-0.6,2.2,0l4.4,4.5c0.4,0.4,1.1,0.4,1.5,0L35,15.5 c0.6-0.6,1.6-0.6,2.2,0l2.2,2.2C40.1,18.3,40.1,19.3,39.4,20z'/%3E%3C/svg%3E%0A");
  padding-right: calc(20px + 1.5em);
}
@mixin is-warning ($c:$color-primary) {
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
  background-size: 1.5em 1.5em;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 52 52' style='enable-background:new 0 0 52 52;' xml:space='preserve'%3E%3Cpath fill='#{url-friendly-colour($c)}' d='M26,0C11.664,0,0,11.663,0,26s11.664,26,26,26s26-11.663,26-26S40.336,0,26,0z M28,41c0,1.104-0.896,2-2,2s-2-0.896-2-2v-2 c0-1.104,0.896-2,2-2s2,0.896,2,2V41z M28,33c0,1.104-0.896,2-2,2s-2-0.896-2-2V11c0-1.104,0.896-2,2-2s2,0.896,2,2V33z'/%3E%3C/svg%3E%0A");
  padding-right: calc(20px + 1.5em);
}

