/*
 * Breadcrumb
 * @description Button styling
 * @author Vismntas Vaidalauskis
 * @copyright 2022, Giraffes 4 Zebras B.V, All rights reserved.
 * @version 2.0
 */

.c-breadcrumb {
  width: 100%;
  max-width: $container-max-width;
  padding: $container-side-padding;
  margin: 0 auto;
  display: none;
  flex-wrap: wrap;
  @media screen and (min-width: 551px) {
    display: flex;
  }

  a:hover {
    color: $color-primary;
    text-decoration: underline;
  }

  li {
    white-space: nowrap;
    text-transform: lowercase;

    br {
      display: none;
    }

    &:not(:last-child):after {
      content: '>';
      padding: 0 5px;

    }
  }
  +.row-overview.overview-page{
    padding: 20px;
    @media screen and (min-width: 551px) {
      padding-top: 40px;
    }
    @media screen and (min-width: 771px) {
      padding-top: 60px;
    }
  }
}