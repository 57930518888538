/*
 * Button
 * @description Button styling
 * @author Vismntas Vaidalauskis
 * @copyright 2022, Giraffes 4 Zebras B.V, All rights reserved.
 * @version 2.0
 */

// Settings
// =====================================================================================================================

$input: (
        paddingVertical: 10px,
        paddingHorizontal: 15px,
        borderWidth: 2px,
        borderRadius: 0px,
        borderColor: $color-primary,
        fontSize: $font-size,
        backgroundColor: $color-white
) !default;

// Styling
// =====================================================================================================================
.flex-form {
  @include grid-container();

  &__row {
    @include grid-col(12);
    margin-bottom: $grid-gutter;
  }

  &__column {
    @include grid-col(12);
    margin-bottom: $grid-gutter;
    @media screen and (min-width: 551px) {
      @include grid-col(6);
    }

    *:last-child {
      margin-bottom: 0;
    }
  }

  &__submit {
    @include grid-col(12);
    display: flex;

    margin-bottom: 0;

    justify-content: flex-end;
    align-items: center;

    input,
    button {
      position: relative;
      z-index: 1;
    }
  }

  .radio-wrapper {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;

    input {
      appearance: none;
      display: none;

      &[type="radio"] + .radio-wrapper__input {
        border-radius: 50%;
      }

      &:checked + .radio-wrapper__input {
        background-color: $color-primary;
      }
    }

    &__input {
      min-width: 1em;
      min-height: 1em;
      border: 2px solid $color-primary;
      background-color: $color-white;
      margin-right: 1ch;
      margin-top: 0.3em;
      cursor: pointer;
    }

    &__label {
      cursor: pointer;
    }
  }

  label {
    width: 100%;
    display: block;
    margin-bottom: 5px;

    a {
      color: $color-primary;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  textarea,
  select,
  input:not([type='submit']) {
    width: 100%;
    padding: map-get($input, paddingVertical) map-get($input, paddingHorizontal);

    font-family: $font-primary;
    font-size: map-get($input, fontSize);

    border: map-get($input, borderWidth) solid map-get($input, borderColor);
    border-radius: map-get($input, borderRadius);
    background-color: map-get($input, backgroundColor);
    line-height: 1;
    vertical-align: middle;

    appearance: none;
    height: #{map-get($input, fontSize) + map-get($input, borderWidth) * 2 + map-get($input, paddingVertical) * 2};

    &::placeholder {
      line-height: 1;
      opacity: 1;
      color: rgba($color-text, 0.5);
    }
  }

  textarea {
    resize: none;
    min-height: 175px;
  }

  select {
    background: {
      image: url("../img/svg/ico-arrow--down.svg");
      repeat: no-repeat;
      position: calc(100% - 16px) center;
      size: 1em 1em;
    };
  }
}