/*
 * Typography
 * @description Typography styling
 * @author Vismntas Vaidalauskis
 * @copyright 2022, Giraffes 4 Zebras B.V, All rights reserved.
 * @version 2.0
 */

// Settings
// =====================================================================================================================
$baseFont: (
        base : ("fontSize":1em, "lineiHeight":1.5)
) !default;
$h1: (
        base : ("fontSize":2em, "lineiHeight":1.25, "fontWeight":700),
        771px : ("fontSize":2.5em, "lineiHeight":1.125),
        1024px: ("fontSize":3em, "lineiHeight":1.05)
) !default;
$h2: (
        base : ("fontSize":1.625em, "lineiHeight":1.15, "fontWeight":700),
        771px : ("fontSize":2em, "lineiHeight":1.25),
        1024px: ("fontSize":2.25em)
) !default;
$h3: (
        base : ("fontSize":1.375em, "lineiHeight":1.36, "fontWeight":700),
        771px : ("fontSize":1.5em, "lineiHeight":1.25),
        1024px: ("fontSize":1.75em)
) !default;
$h4: (
        base : ("fontSize":1.125em, "lineiHeight":1.11, "fontWeight":700)
) !default;
$h5: (
        base : ("fontSize":1em, "fontWeight":700)
) !default;
$h6: (
        base : ("fontSize":0.85em, "fontWeight":700)
) !default;
// Styling
// =====================================================================================================================


/* article default styling */
.h1 {
  @include typography($h1);
}

.h2 {
  @include typography($h2);
}

.h3 {
  @include typography($h3);
}

.h4 {
  @include typography($h4);
}

.h5 {
  @include typography($h5);
}

.h6 {
  @include typography($h6);
}


article {
  p,
  ol,
  ul {
    display: block;
    margin: 0 0 1em 0;

    a:not(.c-btn) {
      color: $color-primary;

      &:hover {
        color: lighten($color-primary, 10%);
      }
    }
  }

  ul {
    padding-left: 20px;

    list-style: disc;
  }

  ol {
    padding-left: 20px;

    list-style: decimal;
  }

  b,
  strong {
    font-weight: bold;
  }

  i,
  em {
    font-style: italic;
  }

  u {
    text-decoration: underline;
  }

  .c-btn {
    margin: 0 0 1em 0;
    line-height: 1;
  }

  q,
  blockquote {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding: 20px;
    margin: 0 0 1em 0;
    font-size: 1.25em;
    line-height: 1.25;
    @media screen and (min-width: 771px) {
      font-size: 1.5em;
      line-height: 1.45;
    }

    p {
      margin-bottom: 0;

      &:before {
        content: '\201d';
      }

      &:after {
        content: '\201c';
      }

      + p {
        text-align: right;
        font-weight: bold;
        display: block;
        font-size: 1em;
        @media screen and (min-width: 771px) {
          font-size: 1.25em;
        }

        &:before,
        &:after {
          content: none;
        }
      }
    }
  }

  h1, h2, h3, h4, h5, h6 {
    display: block;
    margin-bottom: 1em;
  }

  h1 {
    @include typography($h1);
  }

  h2 {
    @include typography($h2);
  }

  h3 {
    @include typography($h3);
  }

  h4 {
    @include typography($h4);
  }

  h5 {
    @include typography($h5);
  }

  h6 {
    @include typography($h6);
  }

  *:last-child {
    margin-bottom: 0;
  }

  mark {
    background-color: lighten($color-primary, 10%);
  }
}