@use "sass:math";

.row-text {
  .u-grid--text {
    @include grid-container();
    margin-top: math.div($row-side-padding, -2);
    margin-bottom: math.div($row-side-padding, -2);
    align-items: flex-start;
    @media screen and (min-width: $container-max-width + 1px){
      @include grid-container($text-gutter);
    }
  }
  &__col {
    @include grid-col(12);
    padding: math.div($row-side-padding, 2) 0;
    flex-grow: 1;
    @media screen and (min-width: 551px){
      @include grid-col(6);
    }
    @media screen and (min-width: 771px){
      @include grid-col(4);
    }
    @media screen and (min-width: $container-max-width + 1px){
      @include grid-col(1, $text-gutter);
      min-width: 250px;
    }
  }
}
