@use "sass:map";

/*
 * Typography
 * @description Typography mixing will assign font size/weight/height based on map with responsive and base styling
 * @author Vismntas Vaidalauskis
 * @copyright 2022, Giraffes 4 Zebras B.V, All rights reserved.
 * @version 2.0
 */

@mixin typography($fs-map) {
  @each $fs-breakpoint, $fs-font-settings in $fs-map {
    $settings: ();
    @if $fs-breakpoint == base {
      font-size: map.get($fs-font-settings, fontSize);
      font-weight: map.get($fs-font-settings, fontWeight);
      line-height: map.get($fs-font-settings, lineiHeight);
    } @else {
      @media screen and (min-width: $fs-breakpoint) {
          font-size: map.get($fs-font-settings, fontSize);
          font-weight: map.get($fs-font-settings, fontWeight);
          line-height: map.get($fs-font-settings, lineiHeight);
      }
    }
  }
}