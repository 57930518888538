@use "sass:math";

.row-contact{
  &__img{
    @include grid-width(6, math.div($grid-gutter, 2))
  }
  &__form{
    @include grid-width(6, math.div($grid-gutter, 2));
    &__title{
      margin-bottom: 20px;
    }
  }
}