.row-simple-text {
  .u-content-block {
    background-color: $color-white;
    box-shadow: 0 3px 6px rgba($color-black, .16);
    padding: calc(100vw / 1920 * 75) calc(100vw / 1920 * 140) calc(100vw / 1920 * 100);

    .details {
      list-style: none;
      padding: 0;
    }

    & > article {
      & > p:first-of-type {
        font-weight: 600;
        @media screen and (min-width: 771px) {
          font-size: 25px;
        }
      }
    }

    article {
      width: 100%;

      h1 {
        max-width: 1080px;
        text-align: center;
        margin: 0 auto calc(100vw / 1920 * 60);
        line-height: 1.6;
      }

      h2 {
        margin-bottom: calc(100vw / 1920 * 35)
      }

      p {
        @media screen and (min-width: 771px) {
          font-size: 20px;
        }
        margin-bottom: calc(100vw / 1920 * 30);
      }
    }
  }

  &__image {
    display: flex;
    flex-wrap:wrap;
    align-items: center;
    margin-bottom: calc(100vw / 1920 * 60);

    &__col {
      @include grid-col(12, 0px);
      @media screen and (min-width: 1051px) {
        @include grid-col(6, 0px);
      }
    }

    &__img {
      @include grid-col(12, 0px);
      @media screen and (min-width: 1051px) {
        @include grid-col(6, 0px);
      }
      min-height: calc(100vw / 1050 * 600);
      position: relative;
      align-self: flex-start;
      @media screen and (min-width: 1051px) {
        min-height: calc(100vw / 1700 * 667);
      }
      @media screen and (min-width: 1701px) {
        min-height: 667px;
      }

      .u-img-container--cover {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        left: 0;
      }
    }

    &.left {
      .row-simple-text__image__col {
        padding-left: 40px;
      }

      .row-simple-text__image__img {
        order: -1;
      }
    }

    &.right {
      .row-simple-text__image__col {
        padding-top: calc(100vw / 1920 * 60);
        @media screen and (min-width: 1051px) {
          padding:0 60px 0 0;
        }
      }

      .row-simple-text__image__img {
        @media screen and (max-width: 1050px) {
          order: -1;
        }
      }
    }
  }
}