@mixin animationDelay ($elements: 20, $step: 0.25){
  @for $i from 1 through $elements {
    &:nth-child(#{$i}){
      animation-delay: #{$i * $step}s;
    }
  }
}

@mixin transitionDelay ($elements: 20, $step: 0.25){
  @for $i from 1 through $elements {
    &:nth-child(#{$i}){
      transition-delay: #{$i * $step}s;
    }
  }
}

@mixin overflow-container($offset: 0, $container: $container-max-width) {
  width: calc(100% + #{$container-side-padding * 2});
  @media screen and (min-width: 771px) {
    width: calc(100% + #{$container-side-padding});
  }
  @media screen and (min-width: $container + 1px) {
    @if $offset == 0 {
      width: calc(100% + (100vw - #{$container}) / 2 + #{$container-side-padding});
    } @else {
      width: calc(100% + (100vw - #{$container}) / 2 + #{$container-side-padding} + #{$offset});
    }

  }
}