.flex-header{
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9001;
  padding: 20px;
  background-color: $color-white;
}

#menu{
  display: flex;
  > .menu-item + .menu-item{
    margin-left: 20px;
  }
  .sub-menu{
    display: none;
  }
}

.logo-header{
  position: relative;
  z-index: 5;
  img,
  svg{
    width: 100%;
    max-height: 50px;
  }
}