@if $cookie-btn {
  .cookie-btn {
    font-size: 1em;
    font-weight: 400;

    display: inline-flex;
    justify-items: center;
    justify-content: center;
    align-items: center;
    align-content: center;

    min-width: 200px;
    padding: 1em 1.5em;

    cursor: pointer;
    transition: all .25s ease;
    text-align: center;
    text-decoration: none;

    color: #fff;
    border: none;
    border-radius: 5px;
    outline: none;

    svg{
      width: 1em;
      height: 1em;
      margin: 0 1ch;
      * {
        fill: currentColor;
      }
    }

    &--settings {
      text-decoration: underline;
      background-color: transparent;

      color: $cookie-href;

      @extend .cookie-btn;
      &:hover {
        color: darken($cookie-href, 15%);
      }
    }
    &--primary {
      background-color: $cookie-primary;

      @extend .cookie-btn;
      &:hover {
        background-color: lighten($cookie-primary, 10%);
      }
    }
    &--secondary {
      background-color: $cookie-secondary;

      @extend .cookie-btn;
      &:hover {
        background-color: lighten($cookie-secondary, 10%);
      }
    }
  }
}