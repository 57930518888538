@use "sass:math";

/*
 * Content
 * @description Content layout styling
 * @author Vismntas Vaidalauskis
 * @copyright 2022, Giraffes 4 Zebras B.V, All rights reserved.
 * @version 2.0
 */

// Settings
// =====================================================================================================================
$container-width: 100% !default;
$container-side-padding: 20px !default;
$row-side-padding: 50px !default;
$row-side-padding--tablet: 40px !default;
$row-side-padding--mobile: 20px !default;
$container-max-width: 1180px + $container-side-padding * 2 !default;

// Styling
// =====================================================================================================================
.u-content-row {
  position: relative;

  width: 100%;
  padding: $row-side-padding--mobile 0;
  @media screen and (min-width: 551px) {
    padding: $row-side-padding--tablet 0;
  }
  @media screen and (min-width: 771px) {
    padding: $row-side-padding 0;
  }
}

.u-content-footer,
.u-content-title,
.u-content-block {
  position: relative;

  width: $container-width;
  max-width: $container-max-width;
  margin: 0 auto;
  padding: 0 $container-side-padding;


  z-index: 5;
}

.u-content-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.u-content-title {
  margin-bottom: math.div($row-side-padding--mobile, 2);
  @media screen and (min-width: 551px) {
    margin-bottom: math.div($row-side-padding--tablet, 2);
  }
  @media screen and (min-width: 771px) {
    margin-bottom: math.div($row-side-padding, 2);
  }
}

.u-content-footer {
  margin-top: math.div($row-side-padding--mobile, 2);
  @media screen and (min-width: 551px) {
    margin-bottom: math.div($row-side-padding--tablet, 2);
  }
  @media screen and (min-width: 771px) {
    margin-bottom: math.div($row-side-padding, 2);
  }
}
