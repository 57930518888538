.flex-footer {
  padding: $row-side-padding--mobile 0 10px;
  background-color: $color-black;
  color: $color-white;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: -1px;
    width: 0;
    height: 0;
    border-style: solid;
    z-index: 5;
    left: 50%;
    transform: translateX(-50%);
    border-width: 40px 38px 0;
    border-color: $color-white transparent transparent transparent;
  }


  @media screen and (min-width: 551px) {
    padding: $row-side-padding--tablet 0 10px;
  }
  @media screen and (min-width: 771px) {
    padding: $row-side-padding 0 10px;
  }

  .u-content-block {
    max-width: 1364px;
  }

  .u-grid {
    justify-content: space-between;
  }

  &__col {
    @include grid-col(12);
    margin-top: 40px;
    text-align: center;
    @media screen and (min-width: 551px) {
      @include grid-col(6);
      text-align: left;
    }
    @media screen and (min-width: 771px) {
      @include grid-col(4);
    }
    @media screen and (min-width: $container-max-width + 1px) {
      @include grid-col(2);
    }

    h2 {
      font-family: $font-secondary;
      font-weight: 900;
      text-transform: uppercase;

      strong {
        color: $color-black;
        text-shadow: -1px -1px 0 $color-white, 1px -1px 0 $color-white, -1px 1px 0 $color-white, 1px 1px 0 $color-white;
      }
    }

    h5 {
      margin-bottom: 20px;
    }

    ul:not(.c-social) li {
      font-size: 1rem;
      line-height: 1;

      a {
        font-size: 1em;

        &:hover {
          text-decoration: underline;
        }
      }

      + li {
        margin-top: 20px;
      }
    }

    &--full {
      @extend .flex-footer__col;
      @include grid-col(12);
    }

    &--wide {
      @extend .flex-footer__col;
      @media screen and (min-width: 551px) {
        @include grid-col(5);
      }
    }

    &.logo-col {
      order: -1;
      @media screen and (min-width: 771px) {
        order: 3;
      }
      @media screen and (min-width: $container-max-width + 1px) {
        order: 0;
      }
    }

    &.social-col {
      @media screen and (min-width: 551px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      .c-btn {
        @media screen and (min-width: 551px) {
          margin-top: auto;
        }
      }
    }

    .c-social {
      & + a {
        display: inline-block;
        margin-top: 20px;
      }
    }
  }

  &__disclaimer {
    @include grid-col(12);

    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    order: 99;
    text-align: center;
    font-size: 0.6rem;
    font-weight: 700;

    &:not(:first-child) {
      padding-top: 10vh;
      @media screen and (min-width: 551px) {
        text-align: left;
      }
      @media screen and (min-width: 1151px) {
        padding-top: $row-side-padding;
      }
    }


    a:hover {
      text-decoration: underline;
    }

    li {
      width: 100%;
      @media screen and (min-width: 551px) {
        width: auto;
      }

      &:after {
        padding: 0 5px;
        @media screen and (min-width: 551px) {
          content: '|';
        }
      }

      &:nth-last-child(1):after,
      &:nth-last-child(2):after {
        content: none;
      }

      &:last-child {
        margin-left: auto;
      }
    }
  }
}

.logo-footer {
  position: relative;
  z-index: 15;

  svg,
  img {
    width: 125px;
    height: auto;
    max-height: 200px;

    * {
      fill: currentColor;
    }
  }
}