@use "sass:math";

/*
 * Button
 * @description Button styling
 * @author Vismntas Vaidalauskis
 * @copyright 2022, Giraffes 4 Zebras B.V, All rights reserved.
 * @version 2.0
 */

// Settings
// =====================================================================================================================
$buttons: (
        "primary":(
                "color": $color-primary,
                "background": transparent,
                "border": $color-primary,
                "hoverColor": $color-white,
                "hoverBackground": $color-primary,
                "hoverBorder": $color-primary,
        )
) !default;

$input: (
        "padding": 10px 15px,
        "borderWidth": 2px,
        "borderRadius": 0px,
        "borderColor": $color-primary,
        "fontSize": $font-size,
        "backgroundColor": $color-white
)!default;


// Styling
// =====================================================================================================================

.c-btn {
  font-size: map-get($input, "fontSize");
  line-height: 1;
  font-weight: 700;

  display: inline-flex;

  padding: map-get($input, "paddingVertical") map-get($input, "paddingHorizontal");

  cursor: pointer;
  transition: all .25s ease;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;

  border: map-get($input, "borderWidth") solid map-get($input, "borderColor");
  border-radius: map-get($input, "borderRadius");
  outline: none;

  appearance: none;

  &:not(:last-of-type) {
    margin-right: 10px;
  }

  @each $type, $settings in $buttons {
    &--#{$type} {
      color: map-get($settings, "color");
      background-color: map-get($settings, "background");
      border-color: map-get($settings, "border");

      &:not([disabled]):hover {
        color: map-get($settings, "hoverColor");
        background-color: map-get($settings, "hoverBackground");
        border-color: map-get($settings, "hoverBorder");
      }
      &[disabled]{
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
}
