/*
 * Mobile menu
 * @description SlickNav Responsive Mobile Menu
 * @author Vismntas Vaidalauskis
 * @copyright 016 Josh Cope - licensed under MIT
 * @version 1.0.10
 */

// Settings
// ====================================================================================================================
$menu-font-size: 1.5rem;
$menu-background: rgba($color-secondary, .8);
$menu-position: fixed;
$menu-position--left: 0;
$menu-position--top: 0;
$menu-width: 100%;

$row-background--hover: transparent;
$text-color: $color-white;
$text-color--hover: $color-primary;

$menu-text-align: center;
$menu-padding--top: 50px;
$menu-padding--bottom: 150px;

$menu-item-spacing--inner: 5px 10px;
$menu-item-spacing--outer: 2px 5px;

@media screen and (min-width: 771px) {
  .hamburger {
    display: none !important;
  }
}

@media screen and (max-width: 770px) {
  #menu {
    display: none !important;
  }
  .slicknav_menu {
    display: block !important;
  }
}

.slicknav_btn, .slicknav_nav .slicknav_item {
  cursor: pointer;
}
.slicknav_btn {
  line-height: 1.125em;
  position: relative;
  display: block;
  float: right;
  padding: .438em .625em;
  vertical-align: middle;
  margin: 5px 5px 6px;
  text-decoration: none;
  border-radius: 4px;
  background-color: #222;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);

  .slicknav_icon-bar + .slicknav_icon-bar {
    margin-top: .188em;
  }
}

.slicknav_menu {
  box-sizing: border-box;
  font-size: $menu-font-size;
  position: $menu-position;
  top: $menu-position--top;
  left: $menu-position--left;
  width: $menu-width;
  background: $menu-background;
  *zoom: 1;
  .slicknav_menutxt {
    font-weight: 700;
    line-height: 1.188em;
    display: block;
    float: left;
    color: #fff;
    text-shadow: 0 1px 3px #000;
  }

  .slicknav_icon {
    float: left;
    width: 1.125em;
    height: .875em;
    margin: .188em 0 0 .438em;

    &:before {
      position: absolute;
      display: block;
      width: 1.125em;
      height: .875em;
      content: '';
      background: 0 0;
    }
  }

  .slicknav_no-text {
    margin: 0;
  }

  .slicknav_icon-bar {
    display: block;
    width: 1.125em;
    height: .125em;
    border-radius: 1px;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
    background-color: #fff;
  }

  &:after, &:before {
    display: table;
    content: ' ';
  }

  &:after {
    clear: both;
  }

  * {
    box-sizing: border-box;
  }
}

.slicknav_nav {
  padding: $menu-padding--top 20px $menu-padding--bottom 0;
  list-style: none;
  font-size: $menu-font-size;
  clear: both;
  overflow: scroll;
  width: calc(100vw + 20px);
  height: calc(100vh + 20px);
  max-height: calc(100vh + 20px);
  margin: 0;
  text-align: $menu-text-align;
  li, ul {
    display: block;
    overflow: hidden;
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .slicknav_arrow {
    font-size: .8em;
    margin: 0 0 0 .4em;
  }

  .slicknav_item a {
    display: inline;
  }

  .slicknav_row, a {
    display: block;
    margin: $menu-item-spacing--outer;
    padding: $menu-item-spacing--inner;
  }
  .slicknav_row:hover {
    color: $text-color--hover;
  }

  a {
    text-decoration: none;
    color: $text-color;

    &:hover {
      color: $text-color--hover;
      background: $row-background--hover;
    }
  }

  .slicknav_parent-link a {
    display: inline;
  }

  .slicknav_txtnode {
    margin-left: 15px;
  }

  .slicknav_item a, .slicknav_parent-link a {
    margin: 0;
    padding: 0;
  }
}

.slicknav_brand {
  font-size: 18px;
  line-height: 30px;
  float: left;
  height: 44px;
  padding: 7px 12px;
  color: #fff;
}

.slicknav_nav li.menu-item.menu-item-has-children > {
  a {
    padding-left: 35px;
  }

  .sub-menu {
    font-size: 20px;
  }
}