.hero{
  width: 100%;

  .u-content-block{
    padding-top: 10vh;
    padding-bottom: 10vh;
    min-height: 35vh;
    background: no-repeat center center / cover;
    box-shadow: 0 3px 6px rgba($color-black, .16);
  }
  &--big{
    @extend .hero;
    .u-content-block{
      min-height: calc(100vh - #{$header-height});
      align-items: center;
      align-content: center;
    }
  }
}