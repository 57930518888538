html {
  font-family: $font-primary;
  color: $color-text;
  font-size: $font-size;
  position:relative;
  @include typography($baseFont);
}

body {
  overflow-x: hidden;

  min-width: 320px;
  background-color: #F4F4F4;
  &.admin-bar{
    .flex-header,
    .slicknav_menu{
      margin-top: 32px;
      @media screen and (max-width:782px){
        margin-top: 46px;
      }
    }
  }
}
a {
  transition: all .25s ease;
  text-decoration: none;

  color: inherit;
}