/*
 * Overlay
 * @description Overlay styling
 * @author Vismntas Vaidalauskis
 * @copyright 2022, Giraffes 4 Zebras B.V, All rights reserved.
 * @version 2.0
 */

// Settings
// =====================================================================================================================
$overlays: (
        "primary":(
                "color": $color-primary,
                "alpha": 0.5,
                "gradient": false
        )
)!default;

// Styling
// =====================================================================================================================

.u-overlay{
  position: relative;
  &:before{
    position: absolute;
    inset: 0;
    z-index: 5;
  }
  @each $type, $settings in $overlays {
    &--#{$type} {
      @extend .u-overlay;
      &:before{
        content: '';
        @if map-get($settings, "gradient") {
          background: map-get($settings, "color");
        } @else {
          background-color: rgba(map-get($settings, "color"), map-get($settings, "alpha"));
        }

      }
    }
  }
}