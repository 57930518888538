/*
 * Pagination
 * @description Button styling
 * @author Vismntas Vaidalauskis
 * @copyright 2022, Giraffes 4 Zebras B.V, All rights reserved.
 * @version 2.0
 */

.c-pagination {
  width: 100%;
  display: flex;
  justify-content: center;

  a {
    color: $color-text;

    &:hover {
      color: $color-primary;
    }
  }

  li {
    &.is-active {
      font-weight: 700;
      pointer-events: none;
      text-decoration: underline;
    }

    &.dots {
      pointer-events: none;
    }

    &.arrow {
      svg {
        width: 1rem;
        height: 1rem;

        * {
          fill: currentColor;
        }
      }
    }

    + li {
      padding-left: 24px;
    }
  }
}