/*
 * Grid
 * @description class based grid system
 * @author Vismntas Vaidalauskis
 * @copyright 2022, Giraffes 4 Zebras B.V, All rights reserved.
 * @version 1.0
 */

// Styling
// =====================================================================================================================

.u-grid {
  @include grid-container;
}

@if $class-grid {
  // Grid columns part with standart gutter
  @for $i from 1 through $grid-columns {
    .u-grid-#{$i} {
      @include grid-col($i);
    }
  }

  @each $breakpoint, $width in $breakpoints {
    @media screen and (min-width: $width + 1px) {
      @for $i from 1 through $grid-columns {
        .u-grid-#{$breakpoint}-#{$i} {
          @include grid-col($i);
        }
      }
    }
  }

  // Grid columns part without gutter
  @for $i from 1 through $grid-columns {
    .u-grid-fluent-#{$i} {
      @include grid-width($i, 0px);
    }
  }

  @each $breakpoint, $width in $breakpoints {
    @media screen and (min-width: $width + 1px) {
      @for $i from 1 through $grid-columns {
        .u-grid-fluent-#{$breakpoint}-#{$i} {
          @include grid-width($i, 0px);
        }
      }
    }
  }
}
