.cookie {
  font-family: inherit;

  position: fixed;
  z-index: 999999999999;
  bottom: 0;
  left: 0;

  overflow: hidden;

  width: 100%;
  max-height: 600px;

  transition: all .5s ease;

  background: $cookie-background;
  box-shadow: 0 0 15px 0 rgba($cookie-shadow, .25);
  font-size: 0.75em;
  color: $cookie-text-color;
  @media screen and (min-width: 551px) {
    font-size: 1em;
  }

  .content-block {
    display: flex;

    width: $container-width;
    max-width: $container-max-width;
    margin: auto;
    padding: 30px $container-side-padding;

    transition: all .5s ease;

    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

  }

  &__info {
    width: 100%;

    &__title {
      color: $cookie-title-color;
      font-size: 1.5em;
    }

    a {
      cursor: pointer;
      transition: all .25s ease;

      color: $cookie-href;

      &:hover {
        color: darken($cookie-href, 15%);
        text-decoration: underline;
      }
    }

    > * + * {
      margin-top: 10px;
    }
  }

  &__choices {
    width: calc(100% + 20px);
    margin-left: -10px;
    margin-right: -10px;
    padding: 10px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    &__title {
      color: $cookie-title-color;
      font-size: 1.5em;
      width: 100%;
      font-weight: 700;
      padding: 0 10px;
      display: block;
    }
    &__item {
      width: calc(100% - 20px);
      margin: 10px;
      border-radius: 10px;
      border: 2px solid $cookie-primary;
      background-color: $color-white;
      padding: 10px;
      flex-grow: 1;
      @media screen  and (min-width: 551px){
        width: calc(100% / 3 - 20px);
      }
      &__title {
        width: 100%;
        font-weight: 700;
        color: $cookie-title-color;
        font-size: 1.25em;
        margin-bottom: 10px;
        display: block;
        padding-left: 25px;
        position: relative;
        line-height: 1;
        &:before{
          content: '';
          position: absolute;
          left: 0;
          width: 0.75em;
          height: 0.75em;
          border: 1px solid $cookie-primary;
          top: 0.1em;
          border-radius: 50%;
          transition: all 0.25s ease;
        }
      }
      &__info{
        display: block;
      }
      &__list{
        display: flex;
        align-items: flex-start;
        svg{
          width: 0.75em;
          min-width: 0.75em;
          height: 0.75em;
          min-height: 0.75em;
          margin-top: 0.5em;
          margin-right: 1ch;
          * {
            fill: $cookie-checkbox;
          }
        }
      }
      input:checked + .cookie__choices__item__title:before{
        background-color: $cookie-primary;
      }
    }
    .cookie__info{
      width: calc(100% - 20px);
      margin: 10px;
      text-align: right;
      line-height: 1.5;
      i{
        font-size: 0.8em;
      }
    }
  }

  &__buttons {
    padding-top: 20px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media screen and (min-width: 551px) {
      justify-content: flex-end;
    }
    * {
      @media screen and (max-width: 550px) {
        min-width: 1px!important;
        width: calc(100% / 2 - 10px);
      }
      + * {
        @media screen and (min-width: 551px) {
          margin-left: 1em;
        }
      }
    }
  }

  .cookie__choices:not(.is-active),
  .cookie__info:not(.is-active){
    pointer-events: none!important;
    opacity: 0!important;
    max-height: 0!important;
    padding: 0!important;
    overflow: hidden!important;
    display: none!important;
  }

  &--overlay {
    height: 100vh;
    max-height: none;

    transition: all .5s ease, z-index 0s linear .55s;

    background-color: rgba($cookie-overlay, $cookie-overlay-alpha);

    @extend .cookie;

    &.is-hidden {
      z-index: -999999999999;

      max-height: none;

      opacity: 0;

      .content-block {
        top: 60%;
      }
    }

    .content-block {
      position: absolute;
      top: 50%;
      left: 50%;

      width: calc(100% - 40px);

      transform: translate(-50%, -50%);

      border-radius: 15px;
      background-color: $cookie-background;
    }
  }

  &.is-hidden {
    max-height: 0;
    pointer-events: none;
  }
}
