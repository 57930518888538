.row-faq {
  .u-content-block-header {
    text-align: left;
    font-weight: 700;
    text-transform: none;
  }


  &.wow {
    .row-faq__item {
      opacity: 0;
    }
    &[style*="visible"] .row-faq__item {
      animation-name: fadeInLeft;
      animation-duration: 0.5s;
      opacity: 1;
      @include animationDelay(15, 0.25);
      @include transitionDelay(15, 0.25);
    }
  }
  &__item {
    width: 100%;
    + .row-faq__item {
      border-top: 1px solid $color-text;
    }

    &__title {
      width: 100%;
      padding: 5px 50px 5px 5px;
      position: relative;
      overflow: hidden;
      margin-bottom: 0;
      cursor: pointer;
      transition: all 0.25s ease;
      @media screen and (min-width: 551px) {
        padding: 10px 50px 10px 0;
      }

      &__wrap {
        position: relative;
        z-index: 2;
      }
    }

    &__content {
      width: 100%;
      padding: 5px 0;
      @media screen and (min-width: 551px) {
        padding: 10px 0 ;
      }
    }

    &__arrow {
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%) rotate(0deg);
      transition: all 0.5s ease, color 0s;
      z-index: 3;
      @media screen and (min-width: 551px) {
        right: 15px;
      }

      svg {
        width: 0.75em;
        height: 0.75em;
        * {
          fill: currentColor;
        }
      }
    }

    &.js-collapsible--expanded {
      .row-faq__item__title {
        color: $color-primary;
      }

      .row-faq__item__arrow {
        transform: translateY(-50%) rotate(-180deg);
      }
    }
  }
}