/*!
 * G4Z Slicing template
 * @description front end styling template for the theme
 * @author Vismntas Vaidalauskis
 * @copyright 2022, Giraffes 4 Zebras B.V, All rights reserved.
 * @version 2.0
 */

@import 'abstracts/variables';
@import 'abstracts/functions/base';
@import 'abstracts/mixins/base';

@import 'vendors/hamburgers/hamburgers';
@import 'vendors/wow/wow-animations';
@import 'vendors/cookie/base';

@import 'base/reset';
@import 'base/typography';
@import 'base/default';

@import 'utilities/base';

@import 'layout/header';
@import 'layout/mobile-menu';
@import 'layout/hero';
@import "layout/base";
@import 'layout/footer';

@import 'components/base';
