.row-text-img {
  position: relative;
  .u-grid--text {
    @include grid-container();
    align-items: flex-start;
    @media screen and (min-width: $container-max-width + 1px) {
      @include grid-container($text-img-gutter, space-between);
      align-items: center;
    }
  }
  .row-text-img__img {
    position: relative;
    .u-img-container--cover {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
    }
  }
  &.left {
    .row-text-img__col {
      order: 2;
      padding-top: 1.25em;
      @media screen and (min-width: 771px){
        padding-top: 0;
      }
    }
  }
  &.right {
    .row-text-img__col {
      order: 2;
      padding-top: 1.25em;
      @media screen and (min-width: 771px){
        padding-top: 0;
        order: 1;
      }
    }
    .row-text-img__img {
      order: 1;
      @media screen and (min-width: 771px){
        order: 2;
      }
    }
  }

  &.small {
    .row-text-img__col {
      @include grid-col(12);
      @media screen and (min-width: 771px){
        @include grid-col($text-img-text-grid--small);
      }
      @media screen and (min-width: $container-max-width + 1px) {
        @include grid-col($text-img-text-grid--small, $text-img-gutter);
      }
    }
    .row-text-img__img {
      @include grid-col(12);
      min-height: $text-img-size--small-mobile-min;
      height: $text-img-size--small-mobile;
      @media screen and (min-width: 771px){
        height: auto;
        min-height: $text-img-size--small;
        @include grid-col($text-img-img-grid--small);
      }
      @media screen and (min-width: $container-max-width + 1px) {
        @include grid-col($text-img-img-grid--small, $text-img-gutter);
      }
    }
  }
  &.normal {
    .row-text-img__col {
      @include grid-col(12);
      @media screen and (min-width: 771px){
        @include grid-col($text-img-text-grid);
      }
      @media screen and (min-width: $container-max-width + 1px) {
        @include grid-col($text-img-text-grid, $text-img-gutter);
      }
    }
    .row-text-img__img {
      @include grid-col(12);
      min-height: $text-img-size--mobile-min;
      height: $text-img-size--mobile;
      @media screen and (min-width: 771px){
        height: auto;
        min-height: $text-img-size;
        @include grid-col($text-img-img-grid);
      }
      @media screen and (min-width: $container-max-width + 1px) {
        @include grid-col($text-img-img-grid, $text-img-gutter);
      }
    }
  }
  &.big {
    .row-text-img__col {
      @include grid-col(12);
      @media screen and (min-width: 771px){
        @include grid-col($text-img-text-grid--big);
      }
      @media screen and (min-width: $container-max-width + 1px) {
        @include grid-col($text-img-text-grid--big, $text-img-gutter);
      }
    }
    .row-text-img__img {
      @include grid-col(12);
      min-height: $text-img-size--big-mobile-min;
      height: $text-img-size--big-mobile-min;
      @media screen and (min-width: 771px){
        height: auto;
        min-height: $text-img-size--big;
        @include grid-col($text-img-img-grid--big);
      }
      @media screen and (min-width: $container-max-width + 1px) {
        @include grid-col($text-img-img-grid--big, $text-img-gutter);
      }
      .u-img-container--cover{
        @include overflow-container();
      }
    }
  }
}
